import React from "react";

import Layout from "../components/Layout";
import SEO from "../components/seo";

import { Link } from "../components/typography/Link";

const LegalPage = (): JSX.Element => {
  return (
    <Layout>
      <SEO title="Legal and Privacy" />
      <main className="prose lg:prose-xl">
        <h1>Legal & Privacy</h1>

        <p>
          Rock climbing, bouldering, mountaineering, and related activities are
          dangerous activities that can lead to serious injury or worse. You
          should not undertake these without proper training or equipment. This
          site is made avaialble "as is", your use of this site as at your own
          discretion and risk. Content is provided for informational purposes
          only.
        </p>

        <h2>Privacy</h2>

        <h3>Cookies</h3>
        <p>
          This site uses cookies to support analytics, by using this site you
          agree to accept cookies associated with your usage.
        </p>

        <h3>Data collection and usage</h3>

        <p>
          <b>Cookies</b> - we use cookies to collect usage data (see below for
          details) and to provide functionality on the website. You may delete
          and/or reject these cookies by configuring your web browser to do so.
        </p>

        <p>
          <b>Usage data</b> - we collect and store website usage data for the
          purposes of improving the site. This includes information such as:
          website you visited from (referer), your anonymized IP address, device
          information (operating system, browser, screen resolution, etc.),
          pages visited, and more. We use{" "}
          <Link href="https://matomo.org/">Matomo</Link> (formerly PIWIK) hosted
          on <Link href="http://www.innocraft.cloud/">InnoCraft Cloud</Link>{" "}
          configured to anonymize IP addreses. Unlike systems like Google
          Analytics, Matomo does not share your data, even in aggregate. For a
          full list of the data Matomo tracks see:{" "}
          <Link href="https://matomo.org/faq/general/faq_18254/">
            What data does Matomo track?
          </Link>{" "}
          If you would like to opt out of usage tracking use a browser add-on
          like <Link href="https://www.ghostery.com/">Ghostery</Link> to block
          Matomo.
        </p>
      </main>

      <div className="mb-8"></div>
    </Layout>
  );
};

export default LegalPage;
